<template>
  <v-card min-width="250px" height="300px" flat class="rounded-lg">
    <v-card-title class="text-color-title caption font-weight-bold py-1">
      {{ title }}
      <v-spacer></v-spacer>
      <v-btn icon @click="menuClick()">
        <v-icon>mdi-menu-open</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text class="text-center pa-2">
      <div class="text-color-main">
        <img :src="song.thumbnail" height="100" width="100" class="mt-4" />
        <div class="body-1 font-weight-black">
          {{ song.title }}
        </div>
        <div class="caption">
          {{ song.album }}
        </div>
        <div class="mt-2">
          <img
            src="@/assets/icons/logo_apple_music.svg"
            alt="apple music"
            class="mx-3"
          />
          <img
            src="@/assets/icons/logo_spotify.svg"
            alt="spotify"
            class="mx-3"
          />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    title: "WORSHIP"
  }),
  props: {
    worship: {
      type: Object,
      default: null
    }
  },
  computed: {
    song() {
      if (this.worship) {
        return {
          title: this.worship.name,
          album: this.worship.album_name,
          thumbnail: this.worship.album_cover
        };
      }

      return {};
    }
  }
};
</script>
